<template>
  <div id="about-textree">
    <div class="about-head animate__animated animate__fadeInLeft">
      <div class="about-title">About TEXTree</div>
      <hr>
    </div>
    <div class="about-content">
      <div class="cover-container animate__animated animate__fadeInUp">
        <div class="cover animate__animated animate__fadeInUp animate__delay-1s" style="background-image: url('/assets/images/about/1181809147.png')" ></div>
      </div>
      <div class="content">
        <div class="content-title animate__animated animate__fadeInLeft">도큐멘테이션를 통해서<br>더 높은 고객 가치를 실현합니다.</div>
        <div class="content-desc  animate__animated animate__fadeInLeft">
          <br>
          문서는 제품의 시작이자 끝 입니다.<br>
          요구분석, 기획, 설계, 제작, 생산, 판매, 고객지원에 이르는 생산 공정의 결과물은 문서에서 시작해서 끝납니다. 문서는 기획, 설계자와 생산자와의 커뮤니케이션 수단일 뿐 아니라, 판매자와 고객과의 약속입니다.<br>
          <br>
          고객 로열티 확보와 기업 브랜딩 실현을 목표로 기술 정보의 끊김 없는 생산과 배포를 지원하기 위해, 텍스트리는 문서 제작과 관리를 위한 전 영역의 솔루션을 제공하기 위해 끊임없이 노력하고 있습니다.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
div#about-textree {
  height: 100%;
  max-width: calc(1600px - 100px - 64px);
  margin: 0 auto 0;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
div.about-title {
  font-family: "Mpntserrat";
  font-size: 60px;
  font-weight: bold;
  color: #008ED6;
}

hr {
  max-width: 100px;
  height: 5px;
  background: #F1B500;
  border: none;
  margin: 48px 0;
}

div.about-content {
  display: flex;
  justify-content: space-evenly;
  margin-left: -96px;
  height: 100%;
}
div.cover-container {
  background-image: url('/assets/images/pattern-blue.png'), url('/assets/images/pattern-yellow.png');
  background-size: 33%, 38%;
  background-position: left 700px, right top;
  background-repeat: no-repeat, no-repeat;
  flex: 0 0 calc(50% - 48px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.cover {
  width: 90%;
  height: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

div.content {
  flex: 0 0 calc(45% - 48px);
}
div.content-title {
  font-family: "Nanum Gothic";
  font-size: 30px;
  font-weight: bold;
  color: #008ED6;
}
div.content-desc {
  font-family: "Nanum Gothic";
  font-size: 20px;
  color: #707070;
  line-height: 40px;
  margin: 24px 0;
}

@media(max-width: 1024px) {
  div.about-title {
    font-size: 48px;
  }
  div.cover-container {
    background-position: left 95%, right top;
  }
  hr {
    margin: 24px 0;
  }
  div.content-title {
    font-size: 24px;
  }
  div.content-desc {
    font-size: 16px;
  }
  div.cover {
    width: 90%;
    height: 95%;
  }
}

@media(max-width: 768px) {
  div#about-textree {
    padding: 0 24px;
  }
  div.about-title {
    font-size: 35px;
  }
  div.about-content {
    flex-direction: column-reverse;
    margin: 0;
  }
  div.content-title {
    font-size: 18px;
  }
  div.content-desc {
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
  }
  div.cover {
    margin: 0 -24px;
    width: calc(100% + 48px);
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  div.cover-container {
    flex-basis: calc(60% - 48px);
  }
}

@media(max-height: 680px) {
  div.about-title {
    font-size: 24px;
  }
  hr {
    margin: 12px 0;
  }
  div.content-title {
    font-size: 12px;
  }
}
</style>
